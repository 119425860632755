import * as Sentry from '@sentry/react';
import './src/styles/global.scss';
import posthog from 'posthog-js';
import React from 'react';
import RootLayout from './src/layouts/rootLayout';

export const onClientEntry = () => {
    Sentry.init({
        dsn: 'https://50b4c613fc8749c5a1a6d92e254e1d2a@sentry.xhecom.net/20',
        beforeSend(event) {
            const url = event.request?.url;
            if (url && (url.includes('localhost') || url.includes('127.0.0.1'))) {
                // Don't report errors when developing on localhost
                return null;
            }
            else {
                // Return the event for other cases
                return event;
            }
        }
    });

    posthog.init('phc_uTMjoFMvhvbjhO7bw2a6aARfehIxhvXh7y9DcZy8UPv', {
        api_host: 'https://eu.posthog.com',
        persistence: 'localStorage'
    });
    window.posthog = window.posthog || posthog;
};

export const onInitialClientRender = () => {

};

export const onRouteUpdate = ({ location }) => {
    posthog.capture('$pageview');
};

export const wrapRootElement = ({ element }) => <RootLayout>{element}</RootLayout>;
