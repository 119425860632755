exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-aida-pay-success-js": () => import("./../../../src/pages/landing-page/aida-pay-success.js" /* webpackChunkName: "component---src-pages-landing-page-aida-pay-success-js" */),
  "component---src-pages-legal-notes-js": () => import("./../../../src/pages/legal-notes.js" /* webpackChunkName: "component---src-pages-legal-notes-js" */),
  "component---src-pages-partner-nda-js": () => import("./../../../src/pages/partner-nda.js" /* webpackChunkName: "component---src-pages-partner-nda-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-platform-aida-gpt-js": () => import("./../../../src/pages/platform/aida-gpt.js" /* webpackChunkName: "component---src-pages-platform-aida-gpt-js" */),
  "component---src-pages-platform-aida-mobile-js": () => import("./../../../src/pages/platform/aida-mobile.js" /* webpackChunkName: "component---src-pages-platform-aida-mobile-js" */),
  "component---src-pages-platform-aida-pay-js": () => import("./../../../src/pages/platform/aida-pay.js" /* webpackChunkName: "component---src-pages-platform-aida-pay-js" */),
  "component---src-pages-platform-aida-xerox-app-js": () => import("./../../../src/pages/platform/aida-xerox-app.js" /* webpackChunkName: "component---src-pages-platform-aida-xerox-app-js" */),
  "component---src-pages-platform-archive-document-management-js": () => import("./../../../src/pages/platform/archive-document-management.js" /* webpackChunkName: "component---src-pages-platform-archive-document-management-js" */),
  "component---src-pages-platform-data-extraction-js": () => import("./../../../src/pages/platform/data-extraction.js" /* webpackChunkName: "component---src-pages-platform-data-extraction-js" */),
  "component---src-pages-platform-index-js": () => import("./../../../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-platform-integrations-js": () => import("./../../../src/pages/platform/integrations.js" /* webpackChunkName: "component---src-pages-platform-integrations-js" */),
  "component---src-pages-platform-overview-business-intelligence-js": () => import("./../../../src/pages/platform/overview-business-intelligence.js" /* webpackChunkName: "component---src-pages-platform-overview-business-intelligence-js" */),
  "component---src-pages-platform-relations-knowledge-graph-js": () => import("./../../../src/pages/platform/relations-knowledge-graph.js" /* webpackChunkName: "component---src-pages-platform-relations-knowledge-graph-js" */),
  "component---src-pages-platform-security-and-certifications-js": () => import("./../../../src/pages/platform/security-and-certifications.js" /* webpackChunkName: "component---src-pages-platform-security-and-certifications-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-contacts-js": () => import("./../../../src/pages/support/contacts.js" /* webpackChunkName: "component---src-pages-support-contacts-js" */),
  "component---src-pages-support-enterprise-js": () => import("./../../../src/pages/support/enterprise.js" /* webpackChunkName: "component---src-pages-support-enterprise-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

